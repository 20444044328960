import React from 'react';

const Footer = () => {
	return(
		<footer className=" width-full pb-0 mt-6">
			<section className="container">
				<h5 className="is-marginless has-text-right width-full mb-0">
					<span className="is-size-7">Lower Properties - Housing with a smile!</span>
					&nbsp;
				</h5>
			</section>
		</footer>
	);
};

export default Footer;
