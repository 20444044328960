import React from 'react';

function Todo() {
	return <section className="container">

		<h1>Todo List</h1>
		<ul>
			
			<li>Add properties slider</li>
			<li>Add better home page welcome blurb</li>
			<li>Add contact form</li>

		</ul>
	</section>
	
}

export default Todo;