import React from 'react';
import propertiesDataObject from '../../propertiesDataObject.js';

export default class Properties extends React.Component {
  generatePropInfoOrList() {
    if ( this.props.subdomain.split("-")[1] ) {

      return <section className="property-single-section">
          <p>
            {propertiesDataObject[this.props.subdomain.split("-")[0]].id} - 
            {propertiesDataObject[this.props.subdomain.split("-")[0]].address} - 
            <a id={propertiesDataObject[this.props.subdomain.split("-")[0]].id} href={propertiesDataObject[this.props.subdomain.split("-")[0]].preApprovalFormLink}>Form Link</a>
          </p>
        </section>;

    } else {
      return <section className="property-list">
        Prop 1<br/>
        Prop 2<br/>
      </section>;
    }
  }
  render() {
    return(
      <section className='container py-6 properties'>
          <h1>Property List - {this.props.subdomain.split("-")[0]}</h1>
          {this.generatePropInfoOrList()}
      </section>
    )
  }
}