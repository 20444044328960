const propertiesDataObject = {
	812: {
		id: '812-12th',
		address: '812 12th Ave. Coralville 52241',
		preApprovalFormLink: 'https://forms.gle/kryfjf9TJ5V13oJe6'
	},
	245: {
		id: '245-Heritage',
		address: '245 Heritage Drive, North Liberty, Iowa',
		preApprovalFormLink: 'https://forms.gle/kryfjf9TJ5V13oJe6'
	}
};

export default propertiesDataObject;