import React from 'react';

function Contact() {
  return <div className='container py-6'>
              <section className="container">
                <p>info@lowprop.com</p><br />
                <addr>
                  Lower Properties <br/>
                  820 South Riverside Dr. #1012 <br/>
                  Iowa City, IA 52246
                </addr>
              </section>
          </div>;
}

export default Contact;