import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Apply from '../components/routes/Apply.js';
import Info from '../components/routes/Info.js';
import Contact from '../components/routes/Contact.js';
import Todo from '../components/routes/Todo.js';
import Properties from '../components/routes/Properties.js';

// A <Switch> looks through its children <Route>s and renders the first one that matches the current URL.

function isSubdomain() {
  return ( window.location.host.split(".")[1] ) ? window.location.host.split(".")[0] : false;
}

function RouterSwitch() {
  var subdomain = isSubdomain()
  if ( subdomain ) {
    return <Properties subdomain={subdomain} />;
  }

  return <Switch>
            <Route path="/info">
              <Info />
            </Route>
            <Route path="/apply">
              <Apply />
            </Route>
            <Route path="/contact">
              <Contact />
            </Route>
            <Route path="/todo">
              <Todo />
            </Route>
            <Route path="/">
              <section className="container">
                <h1 className="title is-size-1">Welcome!</h1>
                <p className="description is-size-5 mb-6">Eastern Iowa Corridor Property Management Services.</p>
                <div>
                  Lower Properties <br/>
                  820 South Riverside Dr. #1012 <br/>
                  Iowa City, IA 52246
                </div>
              </section>
            </Route>
          </Switch>;
}

export default RouterSwitch;